import { Modal, Space } from 'antd';
import SButton from 'components/Standard/SButton';
import SText from 'components/Standard/SText';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { CHECKLIST_MANAGER_STATES } from 'core/utils/constants';
import { getScore } from 'core/utils/ratingsCalculations';
import { every, get, isEmpty, isEqual, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt, withRouter } from 'react-router-dom';
import { getChecklistDefinitionQuestions, getChecklistDefinitionQuestionsSubgroup } from 'redux/selectors/checklistItems/checklistItems';
import { getChecklistsDefinitionsByIds } from 'redux/selectors/checklists';
import { getCurrentUser } from 'redux/selectors/users';
import { setChecklistManagerState } from 'redux/ui/checklistManager/reducer';
import { getAppellationsByIds } from 'redux/selectors/appeals';
import { AppealHead } from './components/AppealHead';

const ChecklistManagerFooter = ({ onSubmit, reviewId, review }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isReviewSubmit, setReviewSubmit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  
  const {
    checklistManagerState,
    currentChecklist,
    questionIdToAnswerValue,
    appealId,
    previousChecklistManagerState
  } = useSelector(state => state.uiChecklistManager, isEqual);

  const checklistDefinitionsByIds = useSelector(
    state => getChecklistsDefinitionsByIds(state),
    isEqual
  );

  const loadingSubmitButton = useSelector(
    state => state.checklistsResource.updateByIdStarted || state.checklistsResource.createStarted
  );

  const reviewAuthorId = useSelector(state =>
    get(state.reviewsResource.byIds[reviewId], 'reviewerId', '')
  );
  
  const appeal = useSelector(state => getAppellationsByIds(state)[appealId], isEqual);
  const checklistDefinitionId = get(currentChecklist, 'checklistDefinitionId');
  const checklistDefinition = get(checklistDefinitionsByIds, checklistDefinitionId, {});
  const checklistQuestionsSubgroup = useSelector(
    state => getChecklistDefinitionQuestionsSubgroup(state, checklistDefinition),
    isEqual
  );
  const currentUserId = useSelector(state => get(getCurrentUser(state), 'id', ''));

  let questions = [];

  if (!isEmpty(currentChecklist) && !isEmpty(checklistDefinition)) {
    questions = checklistDefinition ? checklistQuestionsSubgroup : [];
  }

  const answeredQuestionsValues = Object.values(questionIdToAnswerValue);
  const idLookup = {};
  const uniqueQuestions = [];
  questions.forEach(element => {
    const id = element.id;
    if (!idLookup[id]) {
      idLookup[id] = true; // Mark the ID as encountered
      uniqueQuestions.push(element);
    }
  });

  questions = uniqueQuestions;
  const allQuestionsAnswered =
    questions.length === answeredQuestionsValues.length &&
    every(answeredQuestionsValues, value => !isNil(value)) &&
    !isEmpty(answeredQuestionsValues);

  useEffect(() => {
    if (allQuestionsAnswered) setReviewSubmit(true);
  }, [answeredQuestionsValues]);

  const onEditButton = () => dispatch(setChecklistManagerState(CHECKLIST_MANAGER_STATES.EDITING));
  let score_;
  const onSubmitButton = async event => {
    event.preventDefault();
    localStorage.setItem('totres', score);
    localStorage.setItem('totResult__', score)
    if (isEmpty(currentChecklist)) {
      return Modal.confirm({
        maskClosable: true,
        centered: true,
        title: t('components.checklistManager.checklistManagerHead.emptyChecklistModal.title'),
        content: t(
          'components.checklistManager.checklistManagerHead.emptyChecklistModal.description'
        ),
        okText: t('components.checklistManager.checklistManagerHead.emptyChecklistModal.ok'),
        cancelText: t(
          'components.checklistManager.checklistManagerHead.emptyChecklistModal.cancel'
        ),
        loading: isLoading,
        onOk: async () => {
          setReviewSubmit(true);
          setLoading(true);
          await onSubmit(wholeState, review, reviewId);
          setLoading(false);
        }
      });
    }

    if (!allQuestionsAnswered)
      return Modal.confirm({
        maskClosable: true,
        centered: true,
        title: t('components.checklistManager.checklistManagerHead.allQuestionsAnswered.title'),
        content: t(
          'components.checklistManager.checklistManagerHead.allQuestionsAnswered.description'
        ),
        okText: t('components.checklistManager.checklistManagerHead.allQuestionsAnswered.ok'),
        cancelText: t(
          'components.checklistManager.checklistManagerHead.allQuestionsAnswered.cancel'
        ),
        loading: isLoading,
        onOk: async () => {
          setReviewSubmit(true);
          setLoading(true);
          await onSubmit(wholeState, review, reviewId);
          setLoading(false);
        }
      });
      score_ = score;
    await onSubmit(wholeState, review, reviewId);
  };

  let reviewFormulas;
  if (!!localStorage.getItem('formulas_')) {
    reviewFormulas = localStorage.getItem('formulas_');
    reviewFormulas = JSON.parse(reviewFormulas);
  } 
  let reviewFormulas_ = useSelector(state =>
    get(state.reviewsResource.byIds[reviewId], 'formulaResult', '')
  );
  if (isEmpty(reviewFormulas)) {
    reviewFormulas = reviewFormulas_;
  }

  const wholeState = useSelector(state =>
    state
  );

  const questionsWithValuesAndBindings_ = questions.map(question => ({
    ...question,
    value: questionIdToAnswerValue[question.id],
    percentage: get(question, 'binding.percentage', 1)
  }));

  const score = getScore({
    checklist: currentChecklist,
    checklistDefinition,
    checklistManagerState:
      appeal && !appeal.questionObjectionsProcessed
        ? CHECKLIST_MANAGER_STATES.EDITING
        : checklistManagerState,
    questionsWithValuesAndBindings_
  });
  if (!!localStorage.getItem('totres')) {
    score_ = localStorage.getItem('totres');
  } else { 
    score_ = score
  }
  if (!!localStorage.getItem('totResult__')) {
    score_ = localStorage.getItem('totResult__');
  }

  if (!!localStorage.getItem('totResult__')) {
    score_ = localStorage.getItem('totResult__');
  }

  const componentStates = {
    [CHECKLIST_MANAGER_STATES.EDITING]: (
      <SRow
        type="flex"
        style={{ marginBottom: 0, borderTop: '1px solid #DFE1E8' }}
        backgroundColor="#FFFFFF"
        padding={16}
        align="middle"
        justify="space-between"
      >
        <SText fontSize="18px" ellipsis lineHeight="24px" fontWeight={400}>
          {`${t('general.result')}: ${score}`}
        </SText>
        <SButton
          type="primary"
          loading={loadingSubmitButton}
          showLoad
          onClick={onSubmitButton}
          size="big"
          width="auto"
          fontSize="14px"
        >
          {previousChecklistManagerState === CHECKLIST_MANAGER_STATES.SAVED
            ? t('components.checklistManager.checklistManagerHead.buttons.updateReview')
            : t('components.checklistManager.checklistManagerHead.buttons.saveReview')}
        </SButton>
      </SRow>
    ),
    [CHECKLIST_MANAGER_STATES.SAVED]: (
      <>
        <SRow
          type="flex"
          display="flex"
          style={{ marginBottom: 0, borderTop: '1px solid #DFE1E8' }}
          backgroundColor="#FFFFFF"
          padding={16}
          align="middle"
          justify="space-between"
          wrap={false}
        >
          <SCol flex="none">
            <SText fontSize="18px" ellipsis lineHeight="24px" fontWeight={400}>
              {`${t('general.result')}: ${score_}`}
            </SText>
          </SCol>
          <SCol display="flex" flex="auto" justifyContent="flex-end">
            <Space size={[8, 0]} direction="horizontal">
              {currentUserId === reviewAuthorId && review.status === 'ready' && (
                <SButton
                  type="default"
                  onClick={onEditButton}
                  disabled={appeal}
                  size="big"
                  width="auto"
                  fontSize="14px"
                >
                  {previousChecklistManagerState === CHECKLIST_MANAGER_STATES.INITIAL
                    ? t('components.checklistManager.checklistManagerHead.buttons.updateReview')
                    : t('components.checklistManager.checklistManagerHead.buttons.editReview')}
                </SButton>
              )}
              {currentUserId === reviewAuthorId && review.status === 'in_progress' && (
                <SButton
                  type="default"
                  // onClick={onEditButton}
                  disabled
                  size="big"
                  width="auto"
                  fontSize="14px"
                >
                  {t('components.checklistManager.checklistManagerHead.buttons.inProcessReview')}
                </SButton>
              )}
              <AppealHead reviewId={reviewId} />
            </Space>
          </SCol>
        </SRow>
        {Array.isArray(reviewFormulas?.message || reviewFormulas) && (reviewFormulas?.message || reviewFormulas).length > 0 && (reviewFormulas?.message || reviewFormulas).map(formula => (
          <SRow
              type="flex"
              display="flex"
              style={{ marginBottom: 0, borderTop: '1px solid #DFE1E8' }}
              backgroundColor="#FFFFFF"
              padding={16}
              align="middle"
              justify="space-between"
              wrap={false}>
              <SCol flex="none">
                <SText fontSize="18px" ellipsis lineHeight="24px" fontWeight={400}>
                  {formula.name}: {formula.result}
                </SText>
              </SCol>
            </SRow>
        ))}
      </>
    )
  };
  
  return (
    <>
      <Prompt
        when={checklistManagerState === CHECKLIST_MANAGER_STATES.EDITING && !isReviewSubmit}
        message={t('components.checklistManager.checklistManagerHead.messages.unsavedScore')}
      />
      <SCol span={24}>{componentStates[checklistManagerState]}</SCol>
    </>
  );
};

export default React.memo(withRouter(ChecklistManagerFooter));