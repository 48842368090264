import { Col, DatePicker, Select, Typography } from 'antd';
import ChecklistDefinitionsSelect from 'components/Checklist/ChecklistDefinitionsSelect';
import {
  CALLS_DIRECTIONS,
  DATES_VIEW_FORMAT,
  DATE_PICKER_MODES,
  REVIEW_CALL_STATUSES,
  WEEKEND_VIEW_STATUSES
} from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SpecificUsersSelect from 'components/Inputs/SpecificUsersSelect';
import moment from 'moment';
import { isKpiSettingsHasIntersection } from 'core/utils/isKpiSettingsHasIntersection';
import ChoiceSelect from 'components/Inputs/ChoiceSelect';
import CustomDatePicker from 'components/DatePicker/DatePicker';
import { SRow } from 'components/Standard';
import { useDispatch } from 'react-redux';
import { getModeFromFilters } from 'components/DatePicker/utils';
import { StyledSelect } from '../styled';
import { calcDatesRange, disabledDayOption } from '../utils';
import KpiSettings from './KpiSettings/KpiSettings';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IconButton } from 'components/Buttons';

const { Option } = Select;
const { Text } = Typography;


const FilterForm = ({ filterData }) => {
  const dispatch = useDispatch();

  const {
    unitsIds,
    operatorsIds = [],
    reviewersIds,
    checklistDefinitionsIds,
    isReviewed,
    taskDefinitionsIds,
    direction,
    clientInteractionDirection,
    clientInteractionSymbolicTimeRange,
    clientInteractionTimeFrom,
    clientInteractionTimeTo,
    singleStartDate,
    reviewSymbolicTimeRange,
    reviewTimeFrom,
    reviewTimeTo,
    isWeekendView,
    historyGroupingPeriod,
    settings
  } = filterData.widgetModalData.filters;

  const {
    filtersDefinition,
    filtersData,
    widgetModalData,
    unitsByIds,
    reviewers,
    operators,
    checklistDefinitions,
    taskDefinitionsByIds,
    updateUiWidgetFilters
  } = filterData;

  const { t } = useTranslation();
  const activeOperators = Object.values(operators).filter(o => o.active);
  const activeReviewers = Object.values(reviewers).filter(r => r.active);
  const filterTypeToComponent = {
    dates: () => {
      return (
        <Col span={24}>
          <Text strong>{t('general.period')}</Text>
          <SRow margin="4px 0px 8px 0px">
            <CustomDatePicker
              onChange={filters => {
                dispatch(updateUiWidgetFilters({ ...filters }));
              }}
              value={{
                symbolicTimeRange: clientInteractionSymbolicTimeRange || reviewSymbolicTimeRange,
                timeFrom: clientInteractionTimeFrom || reviewTimeFrom,
                timeTo: clientInteractionTimeTo || reviewTimeTo
              }}
              position="right"
              mode={DATE_PICKER_MODES.default}
              filtersMode={getModeFromFilters({ filters: filterData.widgetModalData.filters })}
            />
          </SRow>
        </Col>
      );
    },
    clientInteractionDates: () => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.communicationDate')}</Text>
        <SRow margin="4px 0px 8px 0px">
          <CustomDatePicker
            onChange={filters => {
              dispatch(
                updateUiWidgetFilters({
                  clientInteractionSymbolicTimeRange: filters.clientInteractionSymbolicTimeRange,
                  clientInteractionTimeFrom: filters.clientInteractionTimeFrom,
                  clientInteractionTimeTo: filters.clientInteractionTimeTo
                })
              );
            }}
            value={{
              symbolicTimeRange: clientInteractionSymbolicTimeRange,
              timeFrom: clientInteractionTimeFrom,
              timeTo: clientInteractionTimeTo
            }}
            position="right"
            mode={DATE_PICKER_MODES.clientInteraction}
          />
        </SRow>
      </Col>
    ),

    reviewDates: () => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.reviewDate')}</Text>
        <SRow margin="4px 0px 8px 0px">
          <CustomDatePicker
            onChange={filters => {
              dispatch(
                updateUiWidgetFilters({
                  reviewSymbolicTimeRange: filters.reviewSymbolicTimeRange,
                  reviewTimeFrom: filters.reviewTimeFrom,
                  reviewTimeTo: filters.reviewTimeTo
                })
              );
            }}
            value={{
              symbolicTimeRange: reviewSymbolicTimeRange,
              timeFrom: reviewTimeFrom,
              timeTo: reviewTimeTo
            }}
            position="right"
            mode={DATE_PICKER_MODES.review}
          />
        </SRow>
      </Col>
    ),

    customDates: () => (
      <Col span={24}>
        <Text strong>
          {`${t('dashboardPage.widget.modal.form.date')}: `}
          <Text type="danger">{t('dashboardPage.widget.modal.messages.requiredField')}</Text>
        </Text>
        <DatePicker
          allowClear={false}
          value={singleStartDate ? moment(singleStartDate) : undefined}
          style={{ width: '100%', margin: ' 4px 0px 8px 0px' }}
          onChange={singleStartDate => {
            const dates = calcDatesRange({ timeTo: singleStartDate });
            updateUiWidgetFilters({
              singleStartDate: singleStartDate.toISOString(true),
              clientInteractionTimeFrom: dates.timeFrom.toISOString(true),
              clientInteractionTimeTo: dates.timeTo.toISOString(true),
              isWeekendView: true,
              historyGroupingPeriod: 'week',
              clientInteractionSymbolicTimeRange: null
            });
          }}
        />
      </Col>
    ),

    isWeekendView: () => (
      <Col span={24}>
        <Text strong>
          {t('dashboardPage.widget.modal.form.isWeekendView')}
          <IconButton
            tooltip={{ title: t('dashboardPage.widget.modal.form.isWeekendViewInfo') }}
            button={{
              icon: <InfoCircleOutlined />,
              size: 'icon'
            }}
          />
        </Text>
        <Select
          style={{ width: '100%', margin: ' 4px 0px 8px 0px' }}
          placeholder={t('dashboardPage.widget.modal.form.isWeekendViewPlaceholder')}
          onChange={value =>
            updateUiWidgetFilters({
              isWeekendView: value
            })
          }
          value={isWeekendView}
        >
          {Object.values(WEEKEND_VIEW_STATUSES).map(item => (
            <Option key={item.title} value={item.value}>
              {t(item.title)}
            </Option>
          ))}
        </Select>
      </Col>
    ),

    unitsIds: () => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.departmentBeingAudited')}</Text>
        <ChoiceSelect
          showSearch
          placeholder={t('dashboardPage.widget.modal.form.departmentBeingAuditedPlaceholder')}
          maxTagCount={0}
          maxTagPlaceholder={selectedKeys => `${t('general.units')}: ${selectedKeys.length}`}
          mode="multiple"
          style={{ margin: ' 4px 0px 8px 0px' }}
          onChange={value => updateUiWidgetFilters({ unitsIds: value })}
          updateFilters={updateUiWidgetFilters}
          filters={unitsIds}
          items={Object.values(unitsByIds)}
          keyName="unitsIds"
          value={unitsIds}
          filterOption={(input, option) => selectSearch({ input, option, searchProp: 'children' })}
        >
          {!isEmpty(unitsByIds)
            ? Object.values(unitsByIds).map(unit => (
                <Option key={unit.id} value={unit.id}>
                  {unit.name}
                </Option>
              ))
            : ''}
        </ChoiceSelect>
      </Col>
    ),

    requiredOperatorsIds: () => (
      <Col span={24}>
        <Text strong>
          {t('dashboardPage.widget.modal.form.operators')}
          {<Text type="danger">{t('dashboardPage.widget.modal.messages.requiredField')}</Text>}
        </Text>
        <SpecificUsersSelect
          placeholder={t('dashboardPage.widget.modal.form.operatorsPlaceHolder')}
          maxTagCount={0}
          maxTagPlaceholder={selectedKeys =>
            `${t('dashboardPage.widget.modal.form.operators')}: ${selectedKeys.length}`
          }
          mode="multiple"
          onChange={value => updateUiWidgetFilters({ operatorsIds: value })}
          value={operatorsIds}
          filterOption={(input, option) => selectSearch({ input, option, searchProp: 'label' })}
          style={{ width: '100%', margin: ' 4px 0px 8px 0px' }}
          usersToSelect={activeOperators}
          updateFilters={updateUiWidgetFilters}
          items={activeOperators}
          keyName="operatorsIds"
        />
      </Col>
    ),

    operatorsIds: () => {
      return (
        <Col span={24}>
          <Text strong>{t('dashboardPage.widget.modal.form.operators')}</Text>
          <SpecificUsersSelect
            placeholder={t('dashboardPage.widget.modal.form.operatorsPlaceHolder')}
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys =>
              `${t('dashboardPage.widget.modal.form.operators')}: ${selectedKeys.length}`
            }
            mode="multiple"
            onChange={value => updateUiWidgetFilters({ operatorsIds: value })}
            updateFilters={updateUiWidgetFilters}
            items={activeOperators}
            keyName="operatorsIds"
            value={operatorsIds}
            filterOption={(input, option) => selectSearch({ input, option, searchProp: 'label' })}
            style={{ width: '100%', margin: ' 4px 0px 8px 0px' }}
            usersToSelect={activeOperators}
          />
        </Col>
      );
    },

    reviewersIds: () => {
      return (
        <Col span={24}>
          <Text strong>{t('dashboardPage.widget.modal.form.reviewers')}</Text>
          <SpecificUsersSelect
            placeholder={t('dashboardPage.widget.modal.form.reviewersPlaceHolder')}
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys =>
              `${t('dashboardPage.widget.modal.form.reviewers')}: ${selectedKeys.length}`
            }
            mode="multiple"
            onChange={value => updateUiWidgetFilters({ reviewersIds: value })}
            value={reviewersIds}
            updateFilters={updateUiWidgetFilters}
            items={activeReviewers}
            keyName="reviewersIds"
            filterOption={(input, option) => selectSearch({ input, option, searchProp: 'label' })}
            style={{ width: '100%', margin: ' 4px 0px 8px 0px' }}
            usersToSelect={activeReviewers}
            hideUserName
          />
        </Col>
      );
    },

    singleChecklistDefinitionId: () => (
      <Col span={24}>
        <Text strong>
          {t('dashboardPage.widget.modal.form.checklistDefinition')}
          <Text type="danger">{t('dashboardPage.widget.modal.messages.requiredField')}</Text>
        </Text>
        <ChecklistDefinitionsSelect
          placeholder={t('general.checklistDefinitionPlaceholder')}
          checklistDefinitions={checklistDefinitions}
          onChange={value => updateUiWidgetFilters({ checklistDefinitionsIds: value })}
          value={checklistDefinitionsIds}
          disabled={isReviewed === 'false'}
          showSearch
          filterOption={(input, option) =>
            selectSearch({ input, option, searchProp: 'searchValue' })
          }
          style={{
            width: '100%',
            margin: '4px 0px 8px 0px'
          }}
          allowAll={false}
        />
      </Col>
    ),
    multipleChecklistDefinitionsIds: () => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.checklistDefinition')}</Text>
        <ChecklistDefinitionsSelect
          placeholder={t('general.checklistDefinitionPlaceholder')}
          mode="multiple"
          checklistDefinitions={checklistDefinitions}
          onChange={value => updateUiWidgetFilters({ checklistDefinitionsIds: value })}
          value={checklistDefinitionsIds}
          disabled={isReviewed === 'false'}
          filterOption={(input, option) =>
            selectSearch({ input, option, searchProp: 'searchValue' })
          }
          style={{
            width: '100%',
            margin: '4px 0px 8px 0px'
          }}
          allowAll={false}
        />
      </Col>
    ),

    requiredMultipleChecklistDefinitionsIds: () => (
      <Col span={24}>
        <Text strong>
          {`${t('dashboardPage.widget.modal.form.checklistDefinition')}: `}
          <Text type="danger">{t('dashboardPage.widget.modal.messages.requiredField')}</Text>
        </Text>
        <ChecklistDefinitionsSelect
          placeholder={t('general.checklistDefinitionPlaceholder')}
          mode="multiple"
          checklistDefinitions={checklistDefinitions}
          maxTagCount={0}
          maxTagPlaceholder={selectedKeys =>
            `${t(
              'clientInteractionsPage.tableFilters.tableReviewsFilters.form.checklistsPlaceholder'
            )}: ${selectedKeys.length}`
          }
          allowClear
          onChange={value => updateUiWidgetFilters({ checklistDefinitionsIds: value })}
          value={checklistDefinitionsIds}
          disabled={isReviewed === 'false'}
          filterOption={(input, option) =>
            selectSearch({ input, option, searchProp: 'searchValue' })
          }
          style={{
            width: '100%',
            margin: '4px 0px 8px 0px'
          }}
          allowAll={false}
        />
      </Col>
    ),

    taskDefinitionsIds: () => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.taskDefinitions')}</Text>
        <StyledSelect
          placeholder={t('dashboardPage.widget.modal.form.taskDefinitionsPlaceHolder')}
          mode="multiple"
          onChange={value => updateUiWidgetFilters({ taskDefinitionsIds: value })}
          value={taskDefinitionsIds}
          filterOption={(input, option) => selectSearch({ input, option, searchProp: 'children' })}
        >
          {!isEmpty(taskDefinitionsByIds)
            ? Object.values(taskDefinitionsByIds).map(taskDefinition => (
                <Option key={taskDefinition.id} value={taskDefinition.id}>
                  {taskDefinition.labelName}
                </Option>
              ))
            : ''}
        </StyledSelect>
      </Col>
    ),

    isReviewed: () => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.isReviewed')}</Text>
        <StyledSelect
          placeholder={t('dashboardPage.widget.modal.form.isReviewedPlaceHolder')}
          onChange={value => updateUiWidgetFilters({ isReviewed: value })}
          value={isReviewed}
        >
          {Object.values(REVIEW_CALL_STATUSES).map(item => (
            <Option key={item.value} value={item.value}>
              {t(item.title)}
            </Option>
          ))}
        </StyledSelect>
      </Col>
    ),

    historyGroupingPeriod: (data, type) => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.dateFormat')}</Text>
        <StyledSelect
          placeholder={t('dashboardPage.widget.modal.form.dateFormatPlaceHolder')}
          onChange={value => updateUiWidgetFilters({ historyGroupingPeriod: value })}
          value={historyGroupingPeriod}
        >
          {Object.values(DATES_VIEW_FORMAT).map(item => (
            <Option
              key={item.value}
              value={item.value}
              disabled={disabledDayOption({
                timeFrom:
                  type === 'table_reviews_count_by_reviewers'
                    ? reviewTimeFrom
                    : clientInteractionTimeFrom,
                timeTo:
                  type === 'table_reviews_count_by_reviewers'
                    ? reviewTimeTo
                    : clientInteractionTimeTo,
                symbolicTimeRange:
                  type === 'table_reviews_count_by_reviewers'
                    ? reviewSymbolicTimeRange
                    : clientInteractionSymbolicTimeRange,
                widgetType: type,
                currentOption: item.value,
                historyGroupingPeriod,
                updateUiWidgetFilters
              })}
            >
              {t(item.title)}
            </Option>
          ))}
        </StyledSelect>
      </Col>
    ),

    direction: () => (
      <Col span={24}>
        <Text strong>{t('dashboardPage.widget.modal.form.direction')}</Text>
        <StyledSelect
          placeholder={t('dashboardPage.widget.modal.form.directionPlaceHolder')}
          onChange={value => updateUiWidgetFilters({ direction: value })}
          value={direction}
        >
          {Object.values(CALLS_DIRECTIONS).map(item => (
            <Option key={item.value} value={item.value}>
              {t(item.title)}
            </Option>
          ))}
        </StyledSelect>
      </Col>
    ),

    clientInteractionDirection: () => (
      <Col span={24}>
          <Text strong>{t('dashboardPage.widget.modal.form.direction')}</Text>
          <StyledSelect
              placeholder={t('dashboardPage.widget.modal.form.directionPlaceHolder')}
              onChange={value => updateUiWidgetFilters({ clientInteractionDirection: value })}
              value={clientInteractionDirection}
              allowClear
          >
              <Option key={CALLS_DIRECTIONS.INCOMING.value} value={CALLS_DIRECTIONS.INCOMING.value}>
                  {t(CALLS_DIRECTIONS.INCOMING.title)}
              </Option>
              <Option key={CALLS_DIRECTIONS.OUTCOMING.value} value={CALLS_DIRECTIONS.OUTCOMING.value}>
                  {t(CALLS_DIRECTIONS.OUTCOMING.title)}
              </Option>
          </StyledSelect>
      </Col>
    ),

    kpiSettings: () => (
      <Col span={24}>
        <Text strong>
          {`KPI: `}
          <Text type="danger">
            {isKpiSettingsHasIntersection({ conditions: settings })
              ? 'Условия не могут иметь пересекающихся значений'
              : t('dashboardPage.widget.modal.messages.requiredField')}
          </Text>
        </Text>
        <KpiSettings updateUiWidgetFilters={updateUiWidgetFilters} kpi={settings} />
      </Col>
    )
  };
  return (
    <Col span={24}>
      {filterTypeToComponent?.[filtersDefinition?.type] &&
        filterTypeToComponent?.[filtersDefinition?.type](filtersData, widgetModalData.type)}
    </Col>
  );
};

export default FilterForm;
