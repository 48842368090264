import { Activity } from 'react-feather';
import { Col, Row, Typography } from 'antd';
import GreenFlag from 'components/Flags/GreenFlag';
import RedFlag from 'components/Flags/RedFlag';
import YellowFlag from 'components/Flags/YellowFlag';
import SCard from 'components/Standard/SCard';
import { ZONE_TO_COLOR_RELATIONS } from 'core/utils/constants';
import { beatifyFloat } from 'core/utils/convertFloat';
import { valueFormatter } from 'core/utils/valueFormatter';
import SmallWidget from 'pages/DashboardPage/SmallWidgets/SmallWidget';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setScoresHistoryChecklistDefinitionId } from 'redux/ui/userAnalyticsPage/reducer';
import styled, { css } from 'styled-components';
import Icon from 'components/Icon';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IconButton } from 'components/Buttons';
import ChecklistDefinitionStatusTag from '../../../components/Checklist/ChecklistDefinitionStatusTag';

const { Text } = Typography;

const flagsCss = css`
  && {
    font-size: clamp(16px, 1.5vw, 24px);
  }
`;

export const SmallValue = styled.span`
  font-size: clamp(16px, 1.5vw, 24px);
  font-weight: 500;
  color: ${props => props.color || 'inherit'};
`;

export const Value = styled.span`
  font-size: clamp(24px, 1.5vw, 40px);
  font-weight: 500;
  color: ${props => props.color || 'inherit'};
  overflow: hidden;
  max-width: 100%;
  display: block;
  text-align: center;
  ${SmallValue} {
    padding: 0 4px;
  }
`;

const ChecklistDefinitionAnalytics = ({
  loading,
  checklistDefinition = {},
  showName = false,
  showAverageScore = true,
  reviewsCount,
  averageScore,
  averageScoreByFinalAssessment,
  averageScoreByConsultingSkills,
  averageScoreBySalesSkills,
  colorZones = {},
  ratingFlags = {}
}) => {
  const dispatch = useDispatch();
  const { name, description } = checklistDefinition;
  const { high = 0, medium = 0, low = 0 } = colorZones;
  const { yellow = 0, green = 0, red = 0 } = ratingFlags;
  const highPercentage =
    high && reviewsCount ? `(${beatifyFloat((high / reviewsCount) * 100)}%)` : '';
  const mediumPercentage =
    medium && reviewsCount ? `(${beatifyFloat((medium / reviewsCount) * 100)}%)` : '';
  const lowPercentage = low && reviewsCount ? `(${beatifyFloat((low / reviewsCount) * 100)}%)` : '';
  const { t } = useTranslation();

  const showHistoryModal = () => {
    dispatch(setScoresHistoryChecklistDefinitionId(checklistDefinition.id));
  };

  let spanValue = 4;

  if (typeof averageScore === 'number') {
    spanValue = 4;
  } else if (typeof averageScoreByFinalAssessment === 'number' || typeof averageScoreByConsultingSkills === 'number' || typeof averageScoreBySalesSkills === 'number') {
    spanValue = 3;
  } else {
    spanValue = 4;
  }

  return (
    <>
      {showName && (
        <SCol span={24}>
          <SCard bodyPadding="16px 24px" rounded bordered shadowed>
            <Row type="flex" justify="space-between" align="middle" alignItems="center">
              <SCol span={22} flex="auto" display="flex" alignItems="center">
                <SText ellipsis>{name}</SText>
                <IconButton
                  tooltip={{
                    title: description,
                    overlayStyle: { width: 500 }
                  }}
                  button={{
                    disabled: !description,
                    icon: <Icon icon={InfoCircleOutlined} />,
                    size: 'icon',
                    style: { width: 24 }
                  }}
                />
                {checklistDefinition.status && (
                  <ChecklistDefinitionStatusTag status={checklistDefinition.status} />
                )}
              </SCol>

              {/*<SCol span={2} flex="none" display="flex" alignItems="center">*/}
              {/*  <IconButton*/}
              {/*    tooltip={{*/}
              {/*      title: t('userAnalyticsPage.widgets.checklistTooltip'),*/}
              {/*      overlayStyle: { width: 500 }*/}
              {/*    }}*/}
              {/*    button={{*/}
              {/*      icon: <Icon icon={Activity} />,*/}
              {/*      size: 'icon',*/}
              {/*      style: { fontSize: '24px', lineHeight: '10px', cursor: 'pointer' },*/}
              {/*      onClick: showHistoryModal*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</SCol>*/}
            </Row>
          </SCard>
        </SCol>
      )}
      <SCol span={24}>
        <Row justify="space-between" type="flex" gutter={[16, 16]}>
          <Col span={spanValue}>
            <SmallWidget
              name={t('userAnalyticsPage.widgets.reviewsCount.name')}
              loading={loading}
              info={{
                tooltip: { title: t('userAnalyticsPage.widgets.reviewsCount.description') }
              }}
            >
              <Value>{reviewsCount}</Value>
            </SmallWidget>
          </Col>
          { showAverageScore ? typeof averageScore === 'number' ? (

              <Col span={spanValue}>
                <SmallWidget
                  name={t('userAnalyticsPage.widgets.averageScore.name')}
                  loading={loading}
                  info={{
                    tooltip: { title: t('userAnalyticsPage.widgets.averageScore.description') }
                  }}
                >
                  <Value>
                    {valueFormatter({
                      value: averageScore,
                      ratingMode: checklistDefinition.ratingMode
                    }) || '0'}
                  </Value>
                </SmallWidget>
              </Col>
          ) : (
            <>
              <Col span={spanValue}>
                <SmallWidget
                  name={t('userAnalyticsPage.widgets.finalAssessment.name')}
                  loading={loading}
                  info={{
                    tooltip: { title: t('userAnalyticsPage.widgets.finalAssessment.description') }
                  }}
                >
                  <Value>
                    {valueFormatter({
                      value: averageScoreByFinalAssessment,
                      ratingMode: checklistDefinition.ratingMode
                    }) || 'N/A'}
                  </Value>
                </SmallWidget>
              </Col>
              <Col span={spanValue}>
                <SmallWidget
                  name={t('userAnalyticsPage.widgets.consultingSkills.name')}
                  loading={loading}
                  info={{
                    tooltip: { title: t('userAnalyticsPage.widgets.consultingSkills.description') }
                  }}
                >
                  <Value>
                    {valueFormatter({
                      value: averageScoreByConsultingSkills,
                      ratingMode: checklistDefinition.ratingMode
                    }) || 'N/A'}
                  </Value>
                </SmallWidget>
              </Col>
              <Col span={spanValue}>
                <SmallWidget
                  name={t('userAnalyticsPage.widgets.salesSkills.name')}
                  loading={loading}
                  info={{
                    tooltip: { title: t('userAnalyticsPage.widgets.salesSkills.description') }
                  }}
                >
                  <Value>
                    {valueFormatter({
                      value: averageScoreBySalesSkills,
                      ratingMode: checklistDefinition.ratingMode
                    }) || 'N/A'}
                  </Value>
                </SmallWidget>
              </Col>
            </>
          ) : null}
          <Col span={spanValue}>
            <SmallWidget
              name={t('userAnalyticsPage.widgets.lowPercentage.name')}
              loading={loading}
              headerColor={ZONE_TO_COLOR_RELATIONS.low}
              bodyHeight="inherit"
              info={{
                tooltip: { title: t('userAnalyticsPage.widgets.lowPercentage.description') }
              }}
            >
              <Value>{low}</Value>
            </SmallWidget>
          </Col>
          <Col span={spanValue}>
            <SmallWidget
              name={t('userAnalyticsPage.widgets.mediumPercentage.name')}
              loading={loading}
              headerColor={ZONE_TO_COLOR_RELATIONS.medium}
              info={{
                tooltip: { title: t('userAnalyticsPage.widgets.mediumPercentage.description') }
              }}
            >
              <Value>{medium}</Value>
            </SmallWidget>
          </Col>
          <Col span={spanValue}>
            <SmallWidget
              name={t('userAnalyticsPage.widgets.highPercentage.name')}
              loading={loading}
              headerColor={ZONE_TO_COLOR_RELATIONS.high}
              info={{
                tooltip: { title: t('userAnalyticsPage.widgets.highPercentage.description') }
              }}
            >
              <Value>{high}</Value>
            </SmallWidget>
          </Col>
          <Col span={spanValue}>
            <SmallWidget
              height="100%"
              name={t('userAnalyticsPage.widgets.flags.name')}
              loading={loading}
              info={{
                tooltip: {
                  title: t('userAnalyticsPage.widgets.flags.description'),
                  placement: 'topRight'
                }
              }}
            >
              <Row type="flex" justify="space-around" align="middle">
                <Col span={8}>
                  <Row>
                    <SCol display="flex" justifyContent="center" span={24}>
                      <RedFlag css={flagsCss} />
                    </SCol>
                    <SCol display="flex" justifyContent="center" span={24}>
                      <Text strong>{red}</Text>
                    </SCol>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <SCol display="flex" justifyContent="center" span={24}>
                      <YellowFlag css={flagsCss} />
                    </SCol>
                    <SCol display="flex" justifyContent="center" span={24}>
                      <Text strong>{yellow}</Text>
                    </SCol>
                  </Row>
                </Col>
                <Col span={8}>
                  <Row>
                    <SCol display="flex" justifyContent="center" span={24}>
                      <GreenFlag css={flagsCss} />
                    </SCol>
                    <SCol display="flex" justifyContent="center" span={24}>
                      <Text strong>{green}</Text>
                    </SCol>
                  </Row>
                </Col>
              </Row>
            </SmallWidget>
          </Col>
        </Row>
      </SCol>
    </>
  );
};

export default ChecklistDefinitionAnalytics;