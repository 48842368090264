import { Modal, Skeleton } from 'antd';
import CopyLinkButton from 'components/CopyLinkButton';
import AttachTag from 'components/PhoneCallPlayer/RecordPlayer/AttachTag';
import SCard from 'components/Standard/SCard';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import SText from 'components/Standard/SText';
import { CHECKLIST_MANAGER_STATES } from 'core/utils/constants';
import { getScore } from 'core/utils/ratingsCalculations';
import { debounce, get, isEmpty, isEqual, pick } from 'lodash';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChecklistDefinitionQuestionGroupsWithQuestions,
  getChecklistDefinitionQuestionsSubgroup
} from 'redux/selectors/checklistItems/checklistItems';
import { getChecklistsDefinitionsByIds } from 'redux/selectors/checklists';
import { getCurrentUser } from 'redux/selectors/users';
import { updateCurrentChecklist } from 'redux/ui/checklistManager/reducer';
import { setAddingComment } from 'redux/ui/clientInteractionPage/reducer';
import Editor from 'components/Comments/Editor';
import DOMPurify from 'dompurify';

import SButton from 'components/Standard/SButton';
import { Edit3 } from 'react-feather';
import Icon from 'components/Icon';
import styled from 'styled-components';
import { InlineCommentText } from 'components/Comments/InlineCommentText';
import QuestionsContainer from './QuestionsContainer';
import {
  ChecklistDefinitionContainer,
  Score,
  ScoreContainer,
  StyledCollapse,
  StyledPanel
} from '../styled';
import { AppealHead } from './AppealHead';
import { getAppellationsByIds } from '../../../../redux/selectors/appeals';

const ChecklistBody = ({
  title,
  customCommunicationView,
  reviewId,
  addingComment,
  onAutoFail,
  comments,
  onDeleteComment,
  onUpdateComment,
  allowCommenting,
  handleCommentSave
}) => {
  const [isOpenCommentEditor, setOpenCommentEditor] = useState(false);
  // * используется для хранения состояния которое нужно применить если при редактировании комментария была нажата кнопка отмены
  const [initialChecklistCommentState, setInitialChecklistCommentState] = useState(null);
  const review = useSelector(state => state.reviewsResource.byIds[reviewId]);
  let reviewFormulas;
  if (!!localStorage.getItem('formulas')) {
    reviewFormulas = localStorage.getItem('formulas');
    reviewFormulas = JSON.parse(reviewFormulas);
    localStorage.removeItem('formulas');
  }

  let reviewFormulas_ = useSelector(state =>
    get(state.reviewsResource.byIds[reviewId], 'formulaResult', '')
  );
  if (isEmpty(reviewFormulas)) {
    reviewFormulas = reviewFormulas_;
  }

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { checklistManagerState, currentChecklist, questionIdToAnswerValue } = useSelector(
    state =>
      pick(state.uiChecklistManager, [
        'checklistManagerState',
        'currentChecklist',
        'questionIdToAnswerValue'
      ]),
    isEqual
  );
  const editorRef = useRef();

  const loading = useSelector(
    state => state.uiChecklistManager.loading || state.checklistDefinitionsResource.loading
  );

  const checklistDefinition = useSelector(
    state => getChecklistsDefinitionsByIds(state)[currentChecklist?.checklistDefinitionId],
    isEqual
  );

  const questionGroups = useSelector(
    state => getChecklistDefinitionQuestionGroupsWithQuestions(state, checklistDefinition),
    isEqual
  );

  const appealId = useSelector(state => state.uiChecklistManager.appealId);

  const appeal = useSelector(state => getAppellationsByIds(state)[appealId]);

  const reviewAuthorId = review?.reviewerId;
  const currentUserId = useSelector(state => getCurrentUser(state)?.id);

  const disabledByState =
    checklistManagerState === CHECKLIST_MANAGER_STATES.SAVED ||
    (review && reviewAuthorId !== currentUserId);

  const handleCommentChange = debounce(e => {
    dispatch(updateCurrentChecklist({ comment: e.text }));
  }, 100);

  // const allMarked = useSelector(
  //   state => state.uiChecklistManager.questionIdToAnswerValue
  // );

  // const allQuestions = useSelector(
  //   state => state.questionsResource.byIds
  // );

  // const checklistWithGroupsSubgroups = useSelector(
  //   state => state.checklistsResource.byIds
  // );

  // const questionToGroupBindingsResource = useSelector(
  //   state => state.questionToGroupBindingsResource.byIds
  // );

  const checklistQuestionsSubgroup = useSelector(
    state => getChecklistDefinitionQuestionsSubgroup(state, checklistDefinition),
    isEqual
  );

  let questions = [];

  if (!isEmpty(currentChecklist) && !isEmpty(checklistDefinition)) {
    questions = checklistDefinition ? checklistQuestionsSubgroup : [];
  }

  // const idLookup = {};
  // const uniqueQuestions = [];
  // questions.forEach(element => {
  //   const id = element.id;
  //   if (!idLookup[id]) {
  //     idLookup[id] = true; // Mark the ID as encountered
  //     uniqueQuestions.push(element);
  //   }
  // });
  // questions = uniqueQuestions;
  const questionsWithValuesAndBindings_ = questions.map(question => ({
    ...question,
    value: questionIdToAnswerValue[question.id],
    percentage: get(question, 'binding.percentage', 1)
  }));
  const score = getScore({
    checklist: currentChecklist,
    checklistDefinition,
    checklistManagerState:
      appeal && !appeal.questionObjectionsProcessed
        ? CHECKLIST_MANAGER_STATES.EDITING
        : checklistManagerState,
    questionsWithValuesAndBindings_
    // allMarked,
    // allQuestions,
    // checklistWithGroupsSubgroups,
    // questionToGroupBindingsResource,
    // questionToGroupSubgroupBindingsResource
  });

  let score_;
  if (!!score && score !== 'N/A') {
    localStorage.setItem('totres__', score);
    score_ = score;
  } else {
    if (!!localStorage.getItem('totres__final')) {
      score_ = localStorage.getItem('totres__final');
      localStorage.removeItem('totres__');
    } else {
      score_ = score;
    }
  }

  // localStorage.removeItem('totres__');
  // const subgroupQuestionIds = questionGroups.flatMap(group => {
  //   let subgroupQuestionIds = group.subgroups.flatMap(subgroup => {
  //     return subgroup.questions.map(question => question.id);
  //   });
  //   return subgroupQuestionIds;
  // });
  // const groupQuestions__ = get(questionGroups[0], 'questions');
  // const groupQuestions___ = groupQuestions__?.filter(element => !subgroupQuestionIds.includes(element.id));

  const comment = currentChecklist?.comment || '';
  if (customCommunicationView) {
    return !isEmpty(currentChecklist) ? (
      <SCard loading={loading}>
        <SRow>
          <SCol span={24}>
            <SRow>
              <ChecklistDefinitionContainer>
                <SRow type="flex" justify="space-between" align="middle" style={{ width: '100%' }}>
                  <SCol>
                    <SText strong>{title}</SText>
                  </SCol>
                  <SCol>
                    <SRow
                      type="flex"
                      justify="space-between"
                      align="middle"
                      gutter={[16, 0]}
                      margin="auto -4px"
                    >
                      <SCol>
                        <AppealHead isCustomCommunication reviewId={review?.id} />
                      </SCol>
                      <SCol>
                        <AttachTag block={false} reviewId={review?.id} />
                      </SCol>
                      {review?.id && (
                        <SCol>
                          <CopyLinkButton reviewId={review?.id} />
                        </SCol>
                      )}
                      <SRow flexDirection="column">
                        <SCol>
                          <ScoreContainer>
                            <span>{t('components.checklistManager.totalScore')}</span>
                            <Score>{score_}</Score>
                          </ScoreContainer>
                        </SCol>
                        {Array.isArray(reviewFormulas?.message || reviewFormulas) &&
                          (reviewFormulas?.message || reviewFormulas).length > 0 &&
                          (reviewFormulas?.message || reviewFormulas).map(formula => (
                            <SCol>
                              <ScoreContainer>
                                <span>{`${formula.name}:`}</span>
                                <Score>{formula.result}</Score>
                              </ScoreContainer>
                            </SCol>
                          ))}
                      </SRow>
                    </SRow>
                  </SCol>
                </SRow>
              </ChecklistDefinitionContainer>
            </SRow>
          </SCol>

          {checklistDefinition?.isGroupable ? (
            questionGroups.map(({ name, id, questions = [], subgroups = [] }) => (
              <StyledCollapse bordered={false} defaultActiveKey="key" key={id}>
                <StyledPanel header={<SText strong>{name}</SText>} key="key" style={{ border: 0 }}>
                  {!!subgroups.length &&
                    subgroups.map(({ name, id, questions = [] }) => (
                      <StyledCollapse bordered={false} defaultActiveKey="key" key={id}>
                        <StyledPanel
                          header={<SText strong>{name}</SText>}
                          key="key"
                          style={{ border: 0 }}
                        >
                          <SRow gutter={[16, 16]}>
                            <QuestionsContainer
                              questions={questions}
                              customCommunicationView={customCommunicationView}
                              disabled={disabledByState}
                              onAutoFail={onAutoFail}
                              comments={comments}
                              checklistDefinition={checklistDefinition}
                              onDeleteComment={onDeleteComment}
                              onUpdateComment={onUpdateComment}
                              allowCommenting={allowCommenting}
                              handleCommentSave={handleCommentSave}
                            />
                          </SRow>
                        </StyledPanel>
                      </StyledCollapse>
                    ))}
                  {subgroups.length === 0 && (
                    <SRow gutter={[16, 16]}>
                      <QuestionsContainer
                        questions={questions}
                        customCommunicationView={customCommunicationView}
                        disabled={disabledByState}
                        onAutoFail={onAutoFail}
                        comments={comments}
                        checklistDefinition={checklistDefinition}
                        onDeleteComment={onDeleteComment}
                        onUpdateComment={onUpdateComment}
                        allowCommenting={allowCommenting}
                        handleCommentSave={handleCommentSave}
                      />
                    </SRow>
                  )}
                </StyledPanel>
              </StyledCollapse>
            ))
          ) : (
            <SRow width="100%" gutter={[16, 16]}>
              <QuestionsContainer
                questions={get(questionGroups[0], 'questions')}
                customCommunicationView={customCommunicationView}
                disabled={disabledByState}
                onAutoFail={onAutoFail}
                comments={comments}
                checklistDefinition={checklistDefinition}
                onDeleteComment={onDeleteComment}
                onUpdateComment={onUpdateComment}
                allowCommenting={allowCommenting}
                handleCommentSave={handleCommentSave}
              />
            </SRow>
          )}
        </SRow>
        <Modal
          maskClosable
          title={t('components.checklistManager.generalCommentModal.title')}
          visible={addingComment}
          onCancel={() => dispatch(setAddingComment(false))}
          cancelButtonProps={{ style: { display: 'none' } }}
          okText={t('components.checklistManager.generalCommentModal.ok')}
          cancelText={t('components.checklistManager.generalCommentModal.cancel')}
          onOk={() => dispatch(setAddingComment(false))}
        >
          {disabledByState ? (
            <SCard>
              <InlineCommentText
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(comment, {
                    ALLOWED_ATTR: ['target', 'href']
                  })
                }}
              />
            </SCard>
          ) : (
            <Editor commentState={{ text: comment }} setCommentState={handleCommentChange} />
          )}
        </Modal>
      </SCard>
    ) : null;
  }

  return loading ? (
    <SCard bordered shadowed>
      <Skeleton active />
    </SCard>
  ) : (
    <SRow>
      <SCol span={24} marginTop="-12px">
        {questionGroups.map(({ questions, name, id, subgroups }) => (
          <SRow gutter={[8, 8]} key={id} paddingTop="12px">
            <SCol span={24}>
              {checklistDefinition?.isGroupable && (
                <SText fontSize="18px" fontWeight="400" lineHeight="24px">
                  {name}
                </SText>
              )}
            </SCol>
            {!!subgroups.length &&
              subgroups.map(({ name, id, questions = [] }) => (
                <SRow
                  gutter={[8, 8]}
                  key={id}
                  paddingTop="12px"
                  display="flex"
                  style={{ width: '100%' }}
                >
                  <SCol span={24}>
                    <SText fontSize="18px" fontWeight="400" lineHeight="24px">
                      {name}
                    </SText>
                  </SCol>
                  <QuestionsContainer
                    questions={questions}
                    customCommunicationView={customCommunicationView}
                    disabled={disabledByState}
                    onAutoFail={onAutoFail}
                    comments={comments}
                    checklistDefinition={checklistDefinition}
                    onDeleteComment={onDeleteComment}
                    onUpdateComment={onUpdateComment}
                    allowCommenting={allowCommenting}
                    handleCommentSave={handleCommentSave}
                  />
                </SRow>
              ))}
            {isEmpty(subgroups) && (
              <QuestionsContainer
                questions={questions}
                customCommunicationView={customCommunicationView}
                disabled={disabledByState}
                onAutoFail={onAutoFail}
                comments={comments}
                checklistDefinition={checklistDefinition}
                onDeleteComment={onDeleteComment}
                onUpdateComment={onUpdateComment}
                allowCommenting={allowCommenting}
                handleCommentSave={handleCommentSave}
              />
            )}
          </SRow>
        ))}
      </SCol>
      <SCol span={24} paddingTop="16px" paddingBottom="8px">
        {disabledByState ? (
          <InlineCommentText
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(comment, {
                ALLOWED_ATTR: ['target', 'href']
              })
            }}
          />
        ) : (
          <SCard
            bordered
            shadowed={
              isOpenCommentEditor
                ? 'inset 0px -1px 0px #597ef7, inset 0px 1px 0px #597ef7, inset -1px 0px 0px #597ef7, inset 1px 0px 0px #597ef7'
                : false
            }
            border={isOpenCommentEditor ? '2px solid #C5D2FB' : null}
          >
            {isOpenCommentEditor ? (
              <SRow>
                <SCol width="100%" type="flex" justify="space-between">
                  <Editor
                    ref={editorRef}
                    commentState={{ text: comment }}
                    setCommentState={handleCommentChange}
                    allowHidden={false}
                    actionsComponent={
                      <>
                        <SButton
                          onClick={() => {
                            handleCommentChange({ text: initialChecklistCommentState });
                            setOpenCommentEditor(!isOpenCommentEditor);
                          }}
                        >
                          {t('general.cancel')}
                        </SButton>
                        <SButton
                          type="primary"
                          onClick={() => {
                            setOpenCommentEditor(!isOpenCommentEditor);
                            setInitialChecklistCommentState(initialChecklistCommentState);
                          }}
                        >
                          {t('general.send')}
                        </SButton>
                      </>
                    }
                  />
                </SCol>
              </SRow>
            ) : (
              <SRow>
                <SCol span={22}>
                  <InlineCommentText
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(comment, {
                        ALLOWED_ATTR: ['target', 'href']
                      })
                    }}
                  />
                </SCol>
                <SCol span={2}>
                  <SRow type="flex" align="end">
                    <SCol>
                      <StyledButton
                        size="icon"
                        icon={<Icon icon={Edit3} size="20px" />}
                        color="var(--black_2)"
                        backgroundColor="transparent"
                        onClick={() => {
                          setOpenCommentEditor(!isOpenCommentEditor);
                          setInitialChecklistCommentState(comment);
                        }}
                      />
                    </SCol>
                  </SRow>
                </SCol>
              </SRow>
            )}
          </SCard>
        )}
      </SCol>
    </SRow>
  );
};

const StyledButton = styled(SButton)`
  &.ant-btn {
    border: none;
    box-shadow: none;
    background-color: transparent;
    width: 24px !important;
  }

  &.ant-btn:hover {
    background-color: transparent;
  }
`;

export default ChecklistBody;