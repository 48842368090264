// import './wdyr'; // ! <--- first import
import { ConfigProvider, Modal } from 'antd';
import ruRu from 'antd/es/locale/ru_RU';
import enUs from 'antd/es/locale/en_US';
import esEs from 'antd/es/locale/es_ES';
import itIt from 'antd/es/locale/it_IT';
import { version } from 'core/config';
import { store } from 'core/store';
import React, { useState } from 'react';
import 'moment/locale/ru';
import 'moment/locale/es';
import 'moment/locale/it';
import 'react-app-polyfill/stable';
import { isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment';
import serviceWorker from 'serviceWorker';
import Chatra from '@chatra/chatra';
import getRoutes from './App';
import './i18next';
import MobileBlocked from './pages/ErrorPages/MobileBlocked';
import './core/polyfills/composedPath';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

serviceWorker.register();

const ANTD_LOCALES_CONVERT = {
  ru: ruRu,
  en: enUs,
  es: esEs,
  it: itIt
};

moment.updateLocale(localStorage.getItem('i18nextLng') || 'ru', {
  week: {
    dow: 1
  }
});

const locale = ANTD_LOCALES_CONVERT[localStorage.getItem('i18nextLng')] || ruRu;

let config = {
  setup: {
    buttonSize: 0,
    customWidgetButton: '.custom-chat-button'
  },
  ID: 'Aic5CKduGFKvzR9Ph',
  integration: {}
};

Chatra('init', config);

// import "./index.scss";
getRoutes().then(routes => {
  const App = () => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
      <Provider store={store}>
        {!isMobile ? (
          <ConfigProvider locale={locale}>
            <ErrorBoundary fallback={() => setIsModalVisible(true)}>{routes}</ErrorBoundary>
            <Modal
              maskClosable
              title={`${t('errorPages.crashApp.title')}😔`}
              visible={isModalVisible}
              onOk={() => window.location.reload()}
              zIndex={5}
              cancelButtonProps={{ style: { display: 'none' } }}
              children={t('errorPages.crashApp.description')}
            />
          </ConfigProvider>
        ) : (
          <MobileBlocked />
        )}
      </Provider>
    );
  };

  ReactDOM.render(<App />, document.getElementById('root'));
});