export default {
  clientInteractionsPage: {
    clientInteractionsTable: {
      deactivatedUserLinkToCommunicationModal: {
        title: 'Um... this user is not active 😢',
        description: 'To view the evaluation, make the user active. ',
        button: 'Settings',
        ok: 'Ok',
        cancel: 'Close'
      },
      emptyTables: 'No communication tables',
      buttons: { createTable: 'Create' }
    },
    clientInteractionMeta: { searchResult: 'Communications found: ' },
    tableFilters: {
      tableConfigurations: { title: 'Configurations Filters' },
      salesPipelines: {
        salesPipelinesPlaceholder: 'Funnel of communication',
        salePipelineStatusesPlaceholder: 'Stages of the communication funnel'
      },
      search: {
        clientPhoneNumberPlaceholder: 'Insert phone number',
        placeholder: 'Search in',
        result: {
          standartResult: 'Search result:',
          bigResult: '20 or more',
          emptyResult: 'Search result: not found'
        },
        searchOptions: {
          clientId: 'Client ID',
          operatorId: 'Operator ID',
          clientPhoneNumber: "Client's phone",
          companyName: 'Company name',
          clientName: "Client's name",
          email: 'Email'
        }
      },
      buttons: {
        applyFilters: 'Apply filters',
        resetFilters: 'Reset filters',
        saveFilters: 'Save configuration filters'
      },
      messages: {
        tableSuccessfullyUpdated: 'Configuration updated',
        updateTableFailed: 'Failed to update a configuration'
      },
      shareConfirm: {
        title:
          'Changes made to the settings of this table will be available to all users who have access to it.',
        description: 'Continue?',
        ok: 'Yes',
        cancel: 'No'
      },
      tableGeneralFilters: {
        title: 'General Info',
        form: {
          communicationsTypes: 'Communications types',
          communicationsTypesShort: 'Types',
          directionPlaceholder: 'Communication direction',
          direction: 'Direction: ',
          datesRange: 'Dates range',
          responseTime: 'Response time:',
          communicationPartsCount: 'Number of messages',
          duration: 'Duration',
          clientInteractionsInChainCount: 'Communications in chain',
          ssValue: 'Sales Skills',
          csValue: 'Consulting Skills',
          frValue: 'Final Result',
          chainCallSSValue: 'Chain-call Sales Skills',
          chainCallCSValue: 'Chain-call Consulting Skills',
          chainCallFRValue: 'Chain-call Final Result',
          chainChatSSValue: 'Chain-chat Sales Skills',
          chainChatCSValue: 'Chain-chat Consulting Skills',
          chainChatFRValue: 'Chain-chat Final Result',
          chainChainSSValue: 'Chain-chain Sales Skills',
          chainChainCSValue: 'Chain-chain Consulting Skills',
          chainChainFRValue: 'Chain-chain Final Result',
          chainTicketSSValue: 'Chain-ticket Sales Skills',
          chainTicketCSValue: 'Chain-ticket Consulting Skills',
          chainTicketFRValue: 'Chain-ticket Final Result',
          chainEmailSSValue: 'Chain-email Sales Skills',
          chainEmailCSValue: 'Chain-email Consulting Skills',
          chainEmailFRValue: 'Chain-email Final Result',
          chainOtherSSValue: 'Chain-other Sales Skills',
          chainOtherCSValue: 'Chain-other Consulting Skills',
          chainOtherFRValue: 'Chain-other Final Result',
          numerAvitoCG: 'Numerator Avito "CG"',
          denomAvitoCG: 'Denominator Avito "CG"',
          numerAvitoSG: 'Numerator Avito "SG"',
          denomAvitoSG: 'Denominator Avito "SG"',
          numerAvitoFG: 'Numerator Avito "FG"',
          denomAvitoFG: 'Denominator Avito "FG"',
          communicationChainId: 'Communication chain',
          unit: 'Department',
          units: 'Departments:',
          levels: 'Levels',
          operators: 'Operators',
          nps: 'NPS',
          reviewStatus: 'Evaluation status',
          status: 'Contact status'
        }
      },
      tableCategoryName: 'Поля из карточек CRM',
      tableReviewsFilters: {
        title: 'Evaluations information',
        infoTooltip: 'This filters group is active only for evaluated communications',
        form: {
          reviewsCount: 'Number of evaluations:',
          checklistsPlaceholder: 'Scorecards',
          reviewResult: 'Evaluation result:',
          reviewAuthor: 'Evaluation author',
          reviewAuthorShort: 'Evaluator',
          datesRange: 'Dates range',
          commentsRatingFlags: 'Flags and Comments',
          tasksDefinitions: 'Tags',
          questions: 'Criteria',
          questionResult: 'The result of the criterion'
        }
      },
      customFieldFilters: { title: 'Additional information' }
    },
    conflicts: {
      title: 'The set settings are not available',
      description1:
        'You have lost access to the selected filter configuration due to a change in profile settings.',
      description2:
        'To change the filter configuration, click Reset Filters and set the new settings. To regain access to the selected filter configuration, contact the Admin.'
    },
    exportModal: {
      ok: 'Yes',
      cancel: 'No',
      title: 'Exporting the list of communications',
      content:
        'After confirmation, the export process will start, select the method to receive the file.',
      contentBreak: 'Are you sure you want to interrupt the export process?',
      cancelWait: 'Wait',
      cancel2: 'Cancel',
      download: 'Download',
      mail: 'Mail'
    },
    drawer: {
      title: 'Table settings',
      columns: {
        title: 'Columns',
        confirmDeleteColumn: {
          title: 'Are you sure you want to delete a column?',
          ok: 'Yes',
          cancel: 'No'
        },
        buttons: {
          viewAddColumnPanel: 'Add a column',
          hidePanel: 'Hide'
        }
      },
      tables: {
        title: 'Table configurations',
        empty: 'No configurations created',
        tableCountMax: 'Maximum number of tables created',
        selectTablePlaceholder: 'Choose active table',
        shareTable: {
          title: 'Share settings',
          messages: {
            tableSuccessfullyShared: 'Access settings updated',
            tableSharedFailed: 'Update access settings failed'
          },
          sharedUserSelectPlaceholder: 'Enter employee name',
          buttons: { apply: 'Share' }
        },
        strings: {
          copyString: '(Copy)',
          openAsccess: '(shared)',
          author: 'author',
          newTableString: 'New table'
        },
        messages: {
          tableSuccessfullyCreated: 'Configuration created',
          tableSuccessfullyCopied: 'Configuration copied',
          tableSuccessfullyUpdated: 'Cconfiguration updated',
          tableSuccessfullyDeleted: 'Configuration deleted',
          tableSuccessfullyExported: 'Communications list exported',
          tableSuccessfullyExportMessage1: 'We will send communications list in email',
          tableSuccessfullyExportMessage2: 'in a few minutes',
          copyTableFailed: 'Failed to create a copy',
          createTableFailed: 'Failed to create the configuration',
          updateTableFailed: 'Failed to update the configuration',
          deleteTableFailed: 'Failed to delete the configuration ',
          exportTableFailed: 'Create communications list export failed'
        },
        confirmDelete: {
          title: 'Are you sure about deleting this configuration?',
          description: 'Configuration cannot be restored after deleting',
          ok: 'Delete',
          cancel: 'Cancel'
        },
        menu: {
          addConfig: 'Add configuration',
          editTable: 'Change configuration name',
          editTableColumns: 'Edit configuration columns',
          copyTable: 'Copy the configuration',
          shareTable: 'Share the configuration',
          export: 'Export to Excel',
          deleteTable: 'Delete'
        },
        buttons: { addConfig: 'Add a configuration' }
      }
    }
  }
};
